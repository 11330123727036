.search-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.search-button {
  height: 100%;
  border-radius: 3px;
  border: 1px solid black;
  background: #fff;
  width: 52px;
}

.search-input-wrapper {
  width: 40%;
}
