.edit-book-club-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-book-club-page-title {
  margin: 0 auto 42px auto;
  font-family: 'RobotoRegular';
  font-size: 24px;
}
