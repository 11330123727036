.input-element-wrapper {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.input {
  height: 32px;
  background: none;
  border-radius: 3px;
  border: 1px solid black;
}

.input-element-wrapper.row {
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}

.input.row {
  align-self: baseline;
}

.input.error:focus {
  outline-color: red;
}
