.button {
  height: 36px;
  width: 45%;
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
  cursor: pointer;
}

.button.space-right {
  margin-right: 12px;
}

.button.small {
  width: auto;
  padding: 8px 22px;
  background-color: #00695c;
  color: #fff;
  border: none;
}
.button.small-green {
  width: auto;
  padding: 8px 22px;
  background-color: #00897b;
  color: #fff;
  border: none;
}

.button.no-outline {
  width: auto;
  border: none;
  color: black;
}
