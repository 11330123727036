.book-club-form {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.input-wrapper {
  margin-bottom: 24px;
  width: 100%;
}

.select {
  background-color: none;
  border-top: none;
  width: 100%;
  height: 300px;
}

.book-club-select-button {
  border: 1px solid black;
  border-radius: 3px;
  cursor: default;
  display: flex;
  flex-direction: row;
  min-height: 24px;
  padding: 8px 12px;
  align-items: center;
  margin-top: 8px;
}

.book-club-select-button-copy {
  flex-grow: 1;
  font-family: 'RobotoRegular';
}

.selected-item {
  margin-right: 3px;
}

.form-buttons-wrapper {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.error-message {
  color: red;
}

.book-club-select-button.error {
  border-color: red;
}

.error-message.main {
  margin-bottom: 12px;
}
