.modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4);
}

.modal-wrapper {
  background-color: #00bfa5;
  margin: auto;
  padding: 30px 30px;
  width: 60%;
  margin-top: 40px;
  text-align: center;
}

.modal-content {
  margin-bottom: 20px;
  color: #fff;
}

.cancel-modal-button {
  margin-right: 12px;
}

.modal-buttons-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
