.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.288);
}

.loading {
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  animation: spinner 1.2s linear infinite;
  border-style: solid;
  border-radius: 50%;
  display: block;
  border-color: #999 #999 #999 transparent;
  border-width: 3px;
  height: 36px;
  width: 36px;
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}
